.thanks {
    text-align: center;
    padding-top: 2rem;

    i {
        font-size: 45px;
        color: var(--primary-blue);
        padding: 10px 0 20px 0;
    }
}
