.subheading {
    color: var(--primary-blue);
    font-weight: 700;
    line-height: 23px;
    margin: 0 0 10px 0;
    text-align: center;
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
        text-align: unset;
    }
}
