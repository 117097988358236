.quote {
    .quote-text {
        text-align: center;
        padding: 2rem 0 0 0;
        p {
            position: relative;
            font-weight: bold;
            font-size: 16px;

            @media screen and (min-width: 768px) {
                font-size: 18px;
            }
            @media screen and (min-width: 922px) {
                font-size: 19px;
            }
        }
        .quote-name {
            font-style: italic;
        }
    }
}
