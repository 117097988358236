.wrapper {
    padding: 3em 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .fslightbox-source {
        width: unset !important;
        height: unset !important;
    }
}

.square-grid {
    --content-width: 60vw;
    --gutter: 10px;
    --columns: 2;
    --row-size: calc((var(--content-width) - (var(--gutter) * (var(--columns) - 1))) / var(--columns));
    display: grid;
    width: 100%;
    max-width: var(--content-width);
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-auto-rows: var(--row-size);
    grid-column-gap: var(--gutter);
    grid-row-gap: var(--gutter);
}

@media (min-width: 450px) {
    .square-grid {
        --columns: 2;
    }
}

@media (min-width: 750px) {
    .square-grid {
        --columns: 3;
    }
}

@media (min-width: 992px) {
    .square-grid {
        --columns: 6;
    }
}

.sdgcontainer {
    cursor: pointer;
}

.sdgcontent {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
}

.sdgcontent .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.sdgcontent:hover .content-overlay {
    opacity: 1;
}

.content-image {
    width: 100%;
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.7s ease-in-out 0s;
    -moz-transition: all 0.7s ease-in-out 0s;
    transition: all 0.7s ease-in-out 0s;
}

.sdgcontent:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.content-details {
    font-size: 30px;
}
