.row-container {
    background-size: cover;
    background-position: 13%;

    @media screen and (min-width: 992px) {
        //width: 50%;
        background-position: unset;
    }
}

.row-content {
    text-align: center;
    padding: 4rem 0;
    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 768px) {
        padding: 4rem 0;
    }
    @media screen and (min-width: 992px) {
    }
}
