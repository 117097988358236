.featured-course {
    position: relative;
    background-color: var(--background-grey);
    width: 80%;
    border-radius: 6px;
    margin-bottom: 3rem;
    margin: auto;

    @media screen and (min-width: 375px) and (max-width: 768px) {
        width: 87%;
        margin: 0 auto 3rem;
    }

    @media screen and (min-width: 768px) {
        min-height: 410px;
        width: 100%;
    }

    @media screen and (min-width: 1200px) {
        min-height: 360px;
    }

    img {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        background-size: cover;
        object-fit: cover;

        width: 100%;
        max-height: 150px;
    }

    .featured-course-info {
        padding: 20px 15px;
        width: 100%;

        .featured-info {
            h5 {
                margin-bottom: 1rem;
                min-height: 32px;
            }

            p {
                font-size: 0.81rem;
                margin-bottom: 20px;
            }

            .course-length {
                position: absolute;
                bottom: 10px;
                right: 20px;
                .icon {
                    color: var(--primary-blue);
                }

                span {
                    font-size: 0.85rem;
                    text-align: right;
                    font-weight: 500;
                    width: 100%;
                    color: var(--primary-blue);
                }
            }
        }
    }
}

.truncate-overflow {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 768px) {
        height: 135px;
        -webkit-line-clamp: 7;
    }

    @media screen and (min-width: 992px) {
        height: 120px;
        -webkit-line-clamp: 6;
    }

    @media screen and (min-width: 1200px) {
        height: 96px;
        -webkit-line-clamp: 5;
    }
}
