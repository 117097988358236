.side-bar{
    padding-left: 40px;
    padding-right: 40px;
    background-color: #fafafa;

    max-width: 360px;

    h3{
        padding: 20px 0;
    }
    
    a:hover{
        text-decoration: none;
      //  transition: all .2s ease-in-out;
       // transform: scale(1.1);
    }       
    
    .side-post{
        margin-bottom: 1rem;
        background-color: #f3f3f3;
        border: 1px solid #eee;
        width:100%;
        border-radius:0.25rem;
        cursor: pointer;
        transition: all .2s ease-in-out;

        .tag{
            color: var(--primary-blue);
            font-size:0.85rem;
            font-weight: bold;
        }
        p{
            font-size: 0.9rem;
        }

        .thumbnail{
            display: inline-block;
            margin-right:0.7rem;
            img{
                border-radius:0.25rem;
                width:90px;
                height: 90px;
                display: block;
                object-fit: cover;
            }
        }

    }
}