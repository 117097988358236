.navbar {
    padding-left: 4rem;
    padding-right: 4rem;
}

@media screen and (max-width: 992px) {
    .navbar {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

#brand-logo {
    max-width: 175px;

    @media screen and (min-width: 992px) {
        max-width: 250px;
    }
}

.nav-link {
    font-size: 13px;
    color: var(--primary-grey) !important;
    &:hover {
        color: var(--primary-blue) !important;
    }
}

.nav-link.active {
    border-bottom: 1.5px solid var(--primary-blue);
}

.hamburger-react {
    div {
        height: 2px !important;
    }
}
