.header {
    position: relative;
    height: 35vh;

    .overlay {
        position: absolute;
        width: 100%;
        height: 35vh;
        left: 0;
        right: 0;
        background-color: #1b2945ba;
        opacity: 0.75;

        @media screen and (min-width: 576px) {
        }
        @media screen and (min-width: 766px) {
            background: linear-gradient(to right, var(--primary-navy), var(--primary-navy), transparent);
            width: 100%;
        }
        @media screen and (min-width: 992px) {
            width: 65%;
        }
        @media screen and (min-width: 1200px) {
            width: 65%;
        }
    }
    .header-img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 65% 57%;
        width: 100%;
        background-color: var(--primary-navy);
        background-blend-mode: soft-light;

        @media screen and (min-width: 576px) {
            background-position: 0% 20%;
        }
        @media screen and (min-width: 576px) {
            background-position: 30% 36%;
        }
    }
    .header-text {
        border-radius: 10px;
        position: absolute;
        text-align: center;
        width: 100%;
        padding: 0 33px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        h1 {
            color: white;
        }

        p {
            padding-top: 10px;
            color: black;
            font-weight: 600;
            font-size: 15px;
            color: white;
        }
    }
}
