.why-us-container {
  padding: 5rem 0;
  height: 100%;
}

.why-us {
  width: 100%;
  padding: 2rem 0;

  @media screen and (min-width: 700px) {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem 2rem;
  }

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: center;
  }

  .icon-box {
    text-align: center;
    width: 80%;
    padding: 1rem;
    background-color: #fff;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 25px;
    border: 1px solid var(--border-grey);
    margin-left: auto;
    margin-right: auto;

    .why-text {
      padding-top: 1rem;
      h6 {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        font-weight: 700;
      }
      p {
        font-size: 13px;
      }
      a {
        color: var(--primary-blue);
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: #008ab7;
        }
      }
    }

    @media screen and (min-width: 700px) {
      width: 30%;
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      width: 29%;
      max-width: 260px;
    }

    @media screen and (min-width: 1000px) {
      margin: 0 1rem;
    }
  }

  .icon-circle {
    display: inline-block;
    background-color: var(--primary-blue);
    font-size: 27px;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    vertical-align: middle;
    border: 2px solid #fff;
  }
}
