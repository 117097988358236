.get-in-touch {
    .section-img {
        background-color: var(--primary-navy);
        background-blend-mode: multiply;
        min-height: 400px !important;
    }

    h2 {
        font-size: 2rem;
        color: white;
    }
    p {
        color: white;
        font-size: 16px;
    }
}
