.video-section {
    .video-image {
        position: relative;
        img {
            border-radius: 6px;
        }
    }
}

.video-image {
    position: relative;
    margin: auto;
    width: 90%;

    @media screen and (min-width: 922px) {
        width: 55%;
    }
    @media screen and (min-width: 1400px) {
        width: 40%;
    }
}

.video-image img {
    width: 100%;
    height: auto;
}

.video-image .icon-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: var(--primary-blue);
    color: white;
    width: 75px;
    height: 75px;
    padding: 12px 24px;
    font-size: 27px;
    cursor: pointer;
    border-radius: 50%;
    line-height: 46px;
    border: 2px solid white;
}

.video-image .icon-circle:hover {
    background-color: #00b7f2;
}
