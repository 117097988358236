.list {
    padding: 0 0 2rem 0;

    @media screen and (min-width: 768px) {
        padding: 4rem 0;
    }

    @media screen and (min-width: 768px) {
        padding: 3rem 0;
    }

    .list-container {
        padding: 1rem 0;
        display: flex;
        align-items: center;

        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: center;
        }
        @media screen and (min-width: 576px) {
            .wrap {
                flex-wrap: wrap;
                display: flex;
            }
        }

        .text {
            text-align: center;
            p {
                font-size: 13px;
                margin-bottom: 0;
            }

            @media screen and (min-width: 576px) and (max-width: 767px) {
                .icon-text {
                    padding-bottom: 2rem;
                }

                .icon-text:nth-child(even) {
                    padding-left: 1.2rem;
                    padding-right: 0;
                }
                .icon-text:nth-child(odd) {
                    padding-right: 1.2rem;
                    padding-left: 0;
                }
            }

            .icon-text {
                margin: auto;
                width: 70%;
                padding-bottom: 2rem;
                padding-top: 3rem;

                @media screen and (min-width: 576px) {
                    width: 50%;
                    margin: unset;
                }

                @media screen and (min-width: 768px) {
                    display: flex;
                    padding-top: 2rem;
                    padding: 2rem;
                }

                .text {
                    @media screen and (min-width: 576px) and (max-width: 767px) {
                        margin: auto;
                    }
                }

                i {
                    font-size: 26px;
                    margin: 0 0 20px 0;
                    color: var(--primary-blue);

                    @media screen and (min-width: 768px) {
                        margin: 0px 1.5rem 0 0;
                    }
                }

                p {
                    margin: 0;
                    font-size: 13px;
                    color: var(--primary-grey);
                }

                h5 {
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            h2 {
                margin-bottom: 0.5rem;
            }

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }

        .image {
            display: flex;
            justify-content: center;

            @media screen and (min-width: 768px) {
                align-self: flex-end;
            }

            img {
                max-height: 400px;
                @media screen and (max-width: 768px) {
                    height: 400px;
                    width: auto;
                }

                @media screen and (min-width: 1200px) {
                    max-height: 600px;
                }
            }

            padding: 1rem 2rem 0 2rem;

            @media screen and (min-width: 576px) {
                padding: 0 2rem 0;
            }
            @media screen and (min-width: 650px) {
                padding: 1rem 0 0 0;
            }
            @media screen and (min-width: 768px) {
                padding: 0 2rem 0 0;
            }
        }
    }
}
