.bloglist{
    .tag {
        color: var(--primary-blue)!important;
        cursor: pointer;
        padding:9px 14px;

    }
    
    .active {
        background-image: linear-gradient(to bottom right, #079fd0, rgb(53, 119, 218));
        box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
        color: white!important;
        padding:9px 14px;
        border-radius:2px;
    }

}