:root {
  --primary-light-grey: #f0f0f0;
  --error-red: #f12e2e;
  --primary-navy-text: #082158;
  --primary-blue: #079fd0;
  --primary-navy: #101f3a;
  --background-grey: #f7f7f7;
  --border-grey: #d2d2d2;

  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}
