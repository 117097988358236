.blog-post{

    h2{
        margin-bottom:0!important;
    }

    .tags{
        display: inline-block;
        margin-right: 10px;
        span {
            color:var(--primary-blue);
            font-size: 0.8rem;
            font-weight: bold;

            &:hover{
                color:#008ab7;
            }
        }
        &:hover{
            text-transform: none;
            text-decoration: none;
        }
    }
}