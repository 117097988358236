.course-testimonials {
    .section-img {
        background-color: var(--primary-navy);
        background-blend-mode: multiply;
        min-height: 400px !important;
    }
    h2 {
        font-size: 2rem;
        color: white;
    }
}

.course-testimonial-container {
    display: flex;
}

.course-testimonials-text {
    margin-bottom: 2rem;
    p {
        color: white;
        font-size: 16px;
    }
}

.course-testimonials {
    height: 300px;
    padding: 0rem 1rem;
    background: #f7f7f7;
    border-radius: 1rem;
    border: 0;
    box-shadow: 9px 9px 6px -3px rgba(146, 146, 146, 0.2);
    display: flex;
    align-items: center;

    @media screen and (min-width: 420px) {
        height: 260px;
    }

    @media screen and (min-width: 576px) {
        height: 250px;
    }

    @media screen and (min-width: 768px) {
        height: 220px;
    }

    @media screen and (min-width: 922px) {
        height: 220px;
    }

    @media screen and (min-width: 1100px) {
        height: 220px;
    }

    p {
        font-size: 13px;
    }
}

.course-testimonials {
    .card-text {
        color: var(--primary-navy-text);
        font-size: 13px;
        padding: 1rem;
        font-weight: bold;
    }

    .ac-content {
        h6 {
            font-weight: 700;
            font-size: 15px;
            text-transform: uppercase;
        }
    }
}
