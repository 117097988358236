.intro {
    @media screen and (min-width: 576px) {
        padding-top: 8rem;
    }
}

.home-service-wrapper {
    max-width: 300px;
    margin: auto;
    .home-services {
        display: inline-block;
    }
}
.testimonial-text {
    h2,
    span {
        color: white;
    }
    padding-bottom: 2rem;
}

.navyHomeBanner {
    padding: 7rem 0;
    h2,
    p {
        color: white;
    }
    p {
        padding-top: 1rem;
        font-size: 17px;
    }
}
