.hero {
  height: 100vh;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0.75;

    @media screen and (min-width: 576px) {
    }
    @media screen and (min-width: 766px) {
      background: linear-gradient(to right, white, #ffffffb0, transparent);
      width: 86%;
    }
  }
  .hero-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 65% 57%;
    width: 100%;

    @media screen and (max-width: 576px) {
      background-position: 7% 57%;
    }
    @media screen and (max-width: 768px) {
      background-position: 40% 57%;
    }
    @media screen and (min-width: 768px) {
      background-position: 30% 57%;
    }
    @media screen and (min-width: 922px) {
      background-position: 0% 57%;
    }
  }

  @-webkit-keyframes slide {
    100% {
      left: 50%;
    }
  }

  @keyframes slide {
    100% {
      left: 50%;
    }
  }

  .hero-text {
    border-radius: 10px;
    position: absolute;
    text-align: center;
    width: 85%;
    left: -20%;
    top: 44%;
    transform: translate(-50%, -66%);
    animation: slide 1.5s forwards;
    animation-delay: 500ms;
    h1 {
      letter-spacing: -1.1px;
    }

    @media screen and (min-width: 768px) {
      text-align: left;
      width: 65%;
      transform: translate(-64%, -81%);
    }

    @media screen and (min-width: 949px) {
      width: 59%;
      transform: translate(-75%, -98%);
    }

    @media screen and (min-width: 950px) {
      width: 63%;
      transform: translate(-69%, -98%);
    }

    @media screen and (min-width: 1069px) {
      padding-right: 2rem;
      transform: translate(-71%, -98%);
    }

    .block {
      max-width: 825px;
    }

    p {
      font-weight: 600;
      font-size: 15px;
      padding: 0.8rem 2rem;

      @media screen and (min-width: 768px) {
        width: 80%;
        padding: 0.8rem 0;
      }

      @media screen and (min-width: 992px) {
        width: 85%;
      }
    }
  }
}
