.contact-form {
    padding: 2rem 0;
    display: inline-block;
    width: 100%;
    margin-top: 10px;

    textarea {
        height: 110px;
    }

    .ac-input {
        background: #ececec none repeat scroll 0 0;
        border: 1px solid #f3f3f3;
        margin-top: 10px;
        padding: 12px 16px;
        width: 100%;
        border-radius: 8px;
    }

    .message-input {
        display: inline-block;
        width: 100%;
    }
    .ac-input textarea {
        background: #f9f9f9 none repeat scroll 0 0;
        border: 1px solid #f9f9f9;
        width: 100%;
        height: 120px;
        padding: 12px 16px;
        border-radius: 8px;
    }
    :focus {
        border: 1.5px solid var(--primary-blue);
        border-radius: 8px;
        outline: none;
    }

    .errorMessage {
        padding: 10px 0 0 10px;
        margin: 0;
        font-size: 13px;
        color: var(--error-red);
    }

    .error-border {
        border: 1px solid var(--error-red);
    }
}
