
.blog-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin:10px;
    max-width: 100%; 
    
    .blog-item{
        width:340px;
        cursor: pointer;
        background-color: #fafafa;
        padding: 20px;
        margin-bottom: 30px;
        border-radius: 0.25rem;

        span{
            color: white;
            margin-left:5px;
            font-weight: bold;
            font-size: 0.8rem;
            float:right;
            padding: 5px 12px;
        }

        &:hover{
            background-color: #f5f5f5;
        }

        a{
            h3{
                color: var(--primary-blue);
                min-height: 45px;
            }
            p {
                color: var(--primary-navy);
            }
        }

        a:hover{
            text-decoration: none;

        }
        
        .item-img {
            position: relative;
            overflow: hidden;
            border-radius: 0;
            z-index: 1;
            border-radius: 2px;
            
            img{
                width: 100%;
                height: 200px;
                display: block;
                object-fit: cover;
            }
        }
        @media screen and (min-width: 710px) {
            width:260px;
        }
        @media screen and (min-width: 992px) {
            width:360px;
        }

    }
}