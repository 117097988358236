.text-image:nth-child(odd) {
    // background-color: var(--background-grey);
}

.text-image {
    padding: 6rem 0;

    @media screen and (min-width: 768px) {
        padding: 7rem 0;
    }

    .row-reverse {
        flex-direction: row-reverse;

        .reverse {
            text-align: right !important;
            @media screen and (min-width: 768px) {
                padding-left: 60px;
            }
            @media screen and (min-width: 922px) {
                padding-left: 80px;
            }
        }
        @media screen and (min-width: 768px) {
            .text {
                text-align: right !important;
            }
        }
    }

    .text-image-container {
        .text {
            text-align: center;
            h2 {
                margin-bottom: 0.8rem;
            }

            @media screen and (min-width: 768px) {
                text-align: left;
            }
        }

        .image {
            img {
                width: 100%;
                max-height: 450px;
                max-width: 400px;
            }

            padding: 4rem 2rem 0 2rem;

            @media screen and (min-width: 576px) {
                padding: 6rem 2rem 2rem;
            }
            @media screen and (min-width: 650px) {
                padding: 3rem;
            }
            @media screen and (min-width: 768px) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .text-image-container {
        img {
            width: 90%;
        }
    }
}

@media screen and (min-width: 768px) {
    .text-image-container {
        display: flex;
        align-items: center;

        img {
            width: 295px;
        }
    }
}

@media screen and (min-width: 992px) {
    .intro-container {
        img {
            width: 350px;
        }
    }
}

.no-border-img {
    img {
        box-shadow: none !important;
        max-height: 350px;
        width: auto !important;
    }
}

.image-top {
    display: flex;
    flex-flow: column-reverse;
}
